import React from "react";
import basket from '../../assets/image/basket.png';

const CustomLink = ({link, title, isMail}) => {
    return (
        <a className='customLink' href={link} target='_blank' rel='noreferrer'>
            <span>{title}</span>
            {!isMail && <img src={basket} alt='basket'/>}
        </a>
    )
}

export default CustomLink