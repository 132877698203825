import React from "react";
import youtube from "../assets/image/youtube_color.png";
import arrow from "../assets/image/arrow.png";

const YoutubeBanner = ({isPl}) => {
    return (
        <a className='youtubeBanner' href="https://youtube.com/user/kingakowalczyk1" target='_blank' rel='noreferrer'>
            <div className='container'>
                <div className='youtubeBanner__circle1'/>
                <div className='youtubeBanner__circle2'/>
                <div className='youtubeBanner__circle3'/>
                <div className='youtubeBanner__content'>
                    <div className='youtubeBanner__circle4'/>
                    <p>{isPl ? 'Zobacz jak używać REVOO REMOVER' : 'Watch how to use REVOO REMOVER'}</p>
                    <img src={arrow} alt="arrow"/>
                    <img className='header__imgLink' src={youtube} alt="youtube ico"/>
                </div>
            </div>
        </a>
    )
}

export default YoutubeBanner;