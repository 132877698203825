import React from "react";

const Footer = () => {
    return (
        <div className='footer'>
            <p>© Copyright 2016 - 2022 | Kinga Kowalczyk | All Rights Reserved | Powered by <a href="https://tastrona.com.pl/" target='_blank' rel='noreferrer'>TA Strona</a></p>
        </div>
    )
}

export default Footer;