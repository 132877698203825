export const data = [
    "./swiperImg/1.jpeg",
    "./swiperImg/2.jpeg",
    "./swiperImg/3.jpeg",
    "./swiperImg/4.jpeg",
    "./swiperImg/5.jpeg",
    "./swiperImg/6.jpeg",
    "./swiperImg/7.jpeg",
    "./swiperImg/8.jpeg",
    "./swiperImg/9.jpeg",
    "./swiperImg/10.jpeg",
]