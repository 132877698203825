import React from "react";
import facebook from '../assets/image/facebook.png';
import instagram from '../assets/image/instagram.png';
import youtube from '../assets/image/youtube.png';
import email from '../assets/image/email.png';
import pl from '../assets/image/pl.png';
import plOff from '../assets/image/pl-off.png';
import en from '../assets/image/en.png';
import enOff from '../assets/image/en-off.png';

const Header = ({isPl, toggler}) => {
    return (
        <div className='header'>
            <div className='container'>
                <div className='header__linkWrapper'>
                    <a href="https://www.facebook.com/pmuprofessional" target='_blank' rel='noreferrer'><img
                        className='header__imgLink' src={facebook} alt="facebook ico"/></a>
                    <a href="https://www.instagram.com/kinga_kowalczyk_pmu_remover/ " target='_blank'
                       rel='noreferrer'><img className='header__imgLink' src={instagram} alt="instagram ico"/></a>
                    <a href="https://youtube.com/user/kingakowalczyk1" target='_blank' rel='noreferrer'><img
                        className='header__imgLink' src={youtube} alt="youtube ico"/></a>
                    <a href="mailto:kinga.kowalczyk@gmail.com" target='_blank' rel='noreferrer'><img
                        className='header__imgLink' src={email} alt="email ico"/></a>
                    {
                        isPl
                            ?
                            <img
                                src={pl}
                                alt="lang img"
                                className='header__imgLink'
                            />
                            :
                            <img
                                src={plOff}
                                alt=""
                                className='header__imgLink'
                                style={{cursor: 'pointer'}}
                                onClick={toggler}
                            />
                    }
                    {
                        isPl
                            ?
                            <img
                                src={enOff}
                                alt="lang img"
                                className='header__imgLink'
                                style={{cursor: 'pointer'}}
                                onClick={toggler}
                            />
                            :
                            <img
                                src={en}
                                alt=""
                                className='header__imgLink'
                            />
                    }
                </div>
            </div>
        </div>
    )
}

export default Header;