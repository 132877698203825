import React from "react";
import arrow from '../assets/image/arrow.png';


const DiscountBanner = ({isPl}) => {
    return (
        <div className='discountBanner'>
            <div className="container">
                <p>@REVOOtattoo</p>
                <img src={arrow} alt="arrow"/>
                {
                    isPl
                    ?
                        <p><span>Oznacz</span> nas w swojej pracy a otrzymasz <span>kod rabatowy</span> na kolejne zamówienie!
                        </p>
                        :
                        <p><span>Tag</span> us in your work to recieve <span>a discount</span> for your next order!</p>

                }
            </div>
        </div>
    )
}

export default DiscountBanner;