import React from "react";

import {EffectCoverflow, Pagination} from "swiper";
import {Swiper, SwiperSlide} from "swiper/react";

import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import {data} from "../data/data";

const SwiperArea = () => {
    return (
        <div className='swiperArea'>
            <div className='container'>
                <Swiper
                    effect={"coverflow"}
                    grabCursor={true}
                    centeredSlides={true}
                    slidesPerView={"auto"}
                    coverflowEffect={{
                        rotate: 50,
                        stretch: 0,
                        depth: 100,
                        modifier: 1,
                        slideShadows: true,
                    }}
                    pagination={true}
                    modules={[EffectCoverflow, Pagination]}
                    className="mySwiper"
                >
                    {data.map((el, index) => <SwiperSlide key={index}><img src={el} alt='img'/></SwiperSlide>)}
                </Swiper>
            </div>
        </div>
    )
}

export default SwiperArea;