import React from "react";
import CustomLink from "./ui/CustomLink";
import emailBannerImg from '../assets/image/emailBannerImg.png';

const EmailBanner = ({isPl}) => {
    return (
        <div className='emailBanner'>
            <div className='container'>
                <div className="emailBanner__leftSide">
                    {
                        isPl
                        ?
                            <p>Jesteś zainteresowany<br/>dystrybucją?</p>
                            :
                            <p>Are you interested<br/>in distribution?</p>
                    }
                    <CustomLink
                        title={isPl ? 'Napisz!' : 'Contact us!'}
                        link='mailto:kinga.kowalczyk@gmail.com'
                        isMail={true}
                    />
                </div>
                <div className="emailBanner__rightSide">
                    <img src={emailBannerImg} alt="img"/>
                </div>
            </div>
        </div>
    )
}

export default EmailBanner;