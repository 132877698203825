import React from "react";
import CustomLink from "./ui/CustomLink";
import row1img from '../assets/image/row1img.png';
import row2img from '../assets/image/row2img.png';
import row2imgEng from '../assets/image/row2imgEng.png';
import rowPoint from '../assets/image/rowPoint.png';
import row3img from '../assets/image/row3img.png';

const Content = ({isPl}) => {
    return (
        <div className='content'>
            <div className='container'>
                <div className='content__firstRow'>
                    <div className='content__firstRow-leftSide'>
                        {
                            isPl
                                ?
                                <p>Produkt ten skierowany jest <span>do tatuażystów oraz trenerów tej sztuki zdobienia ciała.</span> Służy
                                    zarówno do natychmiastowego korygowania pomyłek oraz rozjaśniania starych tatuaży.
                                    Pozwala w bezpieczny sposób pozbyć się pigmentu z każdej okolicy ciała, nawet w dniu
                                    zabiegu. To w pełni bezpieczna forma usuwania zarówno świeżych pomyłek w sztuce
                                    tatuatorskiej, jak i skutecznego rozjaśniania wygojonych tatuaży.</p>
                                :
                                <p>REVOO REMOVER is dedicated to tattoo artists and trainers of this art of body
                                    decoration. This innovative gel is designed to remove ink from the skin without
                                    burning the tissue. Its unique composition allows extracting any color without the
                                    use of a laser. Specially developed composition and application techniques allow you
                                    to get maximum satisfaction without leaving scars. It is used for both: immediate
                                    correction of fresh mistakes and old tattoos brightening.</p>
                        }

                        <CustomLink
                            title={isPl ? 'Kup teraz' : 'Buy now'}
                            link='https://kinga-kowalczyk.com/produkt/revoo-tattoo-remover/?lang=pl'
                            isMail={false}
                        />
                    </div>
                    <div className='content__firstRow-rightSide'>
                        <img src={row1img} alt="img"/>
                    </div>
                </div>
                <div className='content__secondRow'>
                    <div className='content__secondRow-leftSide'>
                        <img src={isPl ? row2img : row2imgEng} alt="img"/>
                    </div>
                    <div className='content__secondRow-rightSide'>
                        <div>
                            <img src={rowPoint} alt="rowPoint"/>
                            <p>{isPl ? 'Nie wywołuje reakcji alergicznej' : 'Doesn\'t cause alergic reactions'}</p>
                        </div>
                        <div>
                            <img src={rowPoint} alt="rowPoint"/>
                            <p>{isPl ? 'Nie stwarza ryzyka powstania blizn' : 'No risk of scarring'}</p>
                        </div>
                        <div>
                            <img src={rowPoint} alt="rowPoint"/>
                            <p>{isPl ? 'Nie zawiera kwasów' : 'Doesn\'t contain acids'}</p>
                        </div>
                        <div>
                            <img src={rowPoint} alt="rowPoint"/>
                            <p>{isPl ? 'Nie jest zasadowy - nie parzy tkanek.' : 'Not alkaline - doesn\'t burn tissue'}</p>
                        </div>
                        <div>
                            <img src={rowPoint} alt="rowPoint"/>
                            <p>{isPl ? 'Bezpieczny do stosowania w miejscach bardzo wrażliwych.' : 'Safe to apply to very sensitive areas'}</p>
                        </div>
                        <div>
                            <img src={rowPoint} alt="rowPoint"/>
                            <p>{isPl ? 'Idealnie usuwa odcienie, z którymi tradycyjne lasery sobie nie radzą.' : 'Perfectly removes shades that traditional lasers cannot cope with.'}</p>
                        </div>
                    </div>
                </div>
                <div className='content__thirdRow'>
                    <div className='content__thirdRow-leftSide'>
                        {
                            isPl
                                ?
                                <p><span>REVOO REMOVER</span> występuje w szklanym opakowaniu o pojemności 30 ml, wraz z
                                    pipetą gwarantującą sterylne użytkowanie. Jest to produkt do użytku wyłącznie
                                    profesjonalnego - a technika zastosowania dołączona jest w formie filmu podczas
                                    zakupu produktu. Ma postać żelu, co bardzo ułatwia przeprowadzenie zabiegu oraz
                                    wspomaga osiągnięcie satysfakcjonujących rezultatów.</p>
                                :
                                <p><span>REVOO REMOVER</span> is a gel in a 30 ml glass container with a pipette
                                    guaranteeing sterile use. This product is for professional use only – the
                                    application manual is included in the form of a video when purchasing the product.
                                    It is in the form of a gel, which greatly facilitates the procedure and helps to
                                    achieve satisfactory results.</p>
                        }
                        <CustomLink
                            title={isPl ? 'Kup teraz' : 'Buy now'}
                            link='https://kinga-kowalczyk.com/produkt/revoo-tattoo-remover/?lang=pl'
                            isMail={false}
                        />
                    </div>
                    <div className='content__thirdRow-rightSide'>
                        <img src={row3img} alt="img"/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Content;