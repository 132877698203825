import React, {useState} from "react";
import Header from "./Header";
import Footer from "./Footer";
import Logo from "./Logo";
import Content from "./Content";
import YoutubeBanner from "./YoutubeBanner";
import SwiperArea from "./SwiperArea";
import DiscountBanner from "./DiscountBanner";
import EmailBanner from "./EmailBanner";

const App = () => {
    const [isPl, setIsPl] = useState(true);
    const langToggle = () => setIsPl(prev => !prev)
    return (
        <div className='app'>
            <Header isPl={isPl} toggler={langToggle}/>
            <Logo/>
            <Content isPl={isPl}/>
            <YoutubeBanner isPl={isPl}/>
            <SwiperArea/>
            <DiscountBanner isPl={isPl}/>
            <EmailBanner isPl={isPl}/>
            <Footer/>
        </div>
    );
}

export default App;
