import React from "react";
import useWindowDimensions from "../hooks/useWindowDimensions";
import banner from '../assets/image/revoo_banner.jpg';
import tabletBanner from '../assets/image/revoo_banner_tablet.jpg';
import mobileBanner from '../assets/image/revoo_banner_mobile.jpg';

const Logo = () => {
    const {width} = useWindowDimensions();


    return <img
        className='logoBanner'
        src={width > 422 ? width > 768 ? banner : tabletBanner : mobileBanner}
        alt="Logo banner"/>
}

export default Logo;